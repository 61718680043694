import { FC, useCallback, useEffect, useState } from 'react';
import Quill, { RangeStatic } from 'quill';
import classNames from 'classnames';
import { AnalyticsEvent, useTrackEvent } from 'services/amplitude';
import {
  AttributeModel,
  TagBlotModel,
  TagModel,
  TagType,
} from 'modules/common/models/Tags';
import useQuill from 'modules/common/hooks/useQuill';
import { tagBlotModelFactory } from 'modules/edit/Editor/Blots/tagBlotModelFactory';
import Styles from 'modules/edit/Tags/tags.module.scss';
import QuillUtil from 'utils/QuillUtil';
import { PresetBlot } from 'modules/edit/v2/Editor/Blots/PresetBlot';
import { TagContainer } from 'modules/edit/Tags/TagContainer';
import { areMultipleSentencesSelected, useHandleCheckboxChange } from './lib';

interface TagProps {
  tag: TagModel;
}

export const Tag: FC<TagProps> = ({ tag }) => {
  const trackEvent = useTrackEvent();
  const [selected, setSelected] = useState(false);
  const [attributeValues, setAttributeValues] = useState<
    Map<string, string | undefined>
  >(() => {
    const map = new Map();
    tag.attributes.forEach((item) => {
      map.set(item.name, item.defaultValue || item.values?.[0]?.value);
    });
    return map;
  });
  const quillInstance = useQuill();
  const [disabled, setDisabled] = useState(false);

  const handleCheckboxChange = useHandleCheckboxChange({
    setSelected,
    setAttributeValues,
  });

  const handleAttrValueChange = useCallback(
    (_: TagModel, attr: AttributeModel, value?: string) => {
      const newMap = new Map(attributeValues);
      newMap.set(attr.name, `${attr.prefix}${value}`);
      if (selected && value) {
        const quill = quillInstance!.getEditor();
        const selection = quill.getSelection(true);
        if (selection) {
          const tagForApply = tagBlotModelFactory(
            tag,
            Array.from(newMap.entries() as any)
          );

          if (!selection.length && _.tagType === TagType.SentenceTags) {
            selection.length = 1;
            if (
              quill
                .getFormat({
                  index: selection.index - 1,
                  length: selection.length,
                })
                .hasOwnProperty(_.id)
            ) {
              selection.index = selection.index - 1;
            }
            // else if (
            //   quill
            //     .getFormat({
            //       index: selection.index - 1,
            //       length: 0,
            //     })
            //     .hasOwnProperty(_.id)
            // ) {
            //   selection.index = selection.index - 2;
            // }
          }
          trackEvent({
            eventName: AnalyticsEvent.EditTag,
            customProperties: {
              tagType: {
                type: tag.tagType,
                displayName: tag.displayName,
              },
            },
          });
          let prevAttributes = JSON.parse(
            quill.getFormat(selection)[tagForApply.id]
          ) as TagBlotModel;

          if (prevAttributes.ai_generated_tag === 'auto') {
            prevAttributes.ai_generated_tag = 'modified';
          }

          quill.formatText(
            selection,
            tagForApply.id,
            JSON.stringify({
              ...prevAttributes,
              ...tagForApply,
            }),
            'user'
          );
        }
      }
      setAttributeValues(newMap);
      const attrTextInput = document.getElementById('text-attribute-input');
      if (attrTextInput) {
        attrTextInput.focus();
      }
    },
    [attributeValues, quillInstance, selected, tag, trackEvent]
  );

  const formatEffectHandler = useCallback(
    (editor: Quill, range: RangeStatic) => {
      if (editor && range) {
        const newRange = { ...range };
        let formatCondition: boolean = !!newRange;
        // cursor logic
        if (tag.tagType === TagType.SentenceTags) {
          if (newRange.length === 0) newRange.length = 1;

          // before and after check
          const formatBefore = editor.getFormat(newRange);
          const formatBeforeNoSpace = editor.getFormat({
            index: range.index,
            length: 0,
          });
          // const formatAfter = editor.getFormat({
          //   index: range.index - 1,
          //   length: 0,
          // });

          let currFormat;
          if (tag.id in formatBefore) {
            currFormat = formatBefore;
          } else if (tag.id in formatBeforeNoSpace) {
            currFormat = formatBeforeNoSpace;
          }
          //
          // else if (tag.id in formatAfter) {
          //   currFormat = formatAfter;
          // }

          formatCondition &&= !!(
            currFormat && Object.values(currFormat).length
          );
          if (currFormat && currFormat[tag.id]) {
            const format = JSON.parse(currFormat[tag.id]) as TagBlotModel;
            const map = new Map(format.attributeValues);
            setAttributeValues(map);
          }
        } else if (formatCondition) {
          // selection logic
          const formats = editor.getFormat(newRange);
          if (formats.hasOwnProperty(tag.id)) {
            const format = JSON.parse(formats[tag.id]) as TagBlotModel;
            const map = new Map(format.attributeValues);
            setAttributeValues(map);
          } else {
            formatCondition = false;
          }
        }

        if (!formatCondition && editor.hasFocus()) {
          setSelected(false);
        } else if (formatCondition) {
          setSelected(true);
        }
      }
    },
    [tag]
  );

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    const range = editor?.getSelection();
    if (editor && range) {
      formatEffectHandler(editor, range);
    }
  }, [formatEffectHandler, quillInstance]);

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    if (editor) {
      const selectionChangeHandler = (range: {
        index: number;
        length: number;
      }) => {
        formatEffectHandler(editor, range);
      };

      editor.on('selection-change', selectionChangeHandler);
      return () => {
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [formatEffectHandler, quillInstance]);

  const handleDisabledHandler = useCallback(
    (
      editor: Quill,
      range: {
        index: number;
        length: number;
      }
    ) => {
      if (range) {
        const newRange = { ...range };
        if (newRange.length === 0) {
          newRange.length = 1;
        }
        const blots = QuillUtil.getInlineWithoutSentences(editor, newRange);

        if (areMultipleSentencesSelected(editor, range))
          return setDisabled(true);

        if (blots.length) {
          if (blots.some((b) => b.statics.blotName === PresetBlot.blotName)) {
            return setDisabled(true);
          }

          let mostInnerElementIndex = blots.length - 1;

          const firstBlotLength = blots[mostInnerElementIndex].length();
          if (firstBlotLength > newRange.length) {
            // Inner
            const parent = blots[mostInnerElementIndex];
            const parentAllowedTags =
              parent.statics.allowedTags || [] as string[];
            if (
              parentAllowedTags.includes('*') ||
              parentAllowedTags.includes(tag.name)
            ) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          } else {
            if (firstBlotLength === newRange.length) {
              const parent = blots[mostInnerElementIndex];
              const parentAllowedTags = parent.statics.allowedTags || [] as string[];
              if (
                blots.some((b) => tag.id === b.statics.blotName) ||
                parentAllowedTags.includes('*') ||
                parentAllowedTags.includes(tag.name)
              ) {
                setDisabled(false);
              } else {
                setDisabled(true);
              }
            } else {
              // Outer
              if (tag.allowedTags.includes('*')) {
                setDisabled(false);
              } else if (
                blots.some((b) => {
                  return !tag.allowedTags.includes(b.statics.ssmlTagName);
                })
              ) {
                setDisabled(true);
              }
            }
          }
        } else {
          setDisabled(false);
        }
      }
    },
    [tag.allowedTags, tag.id, tag.name]
  );

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    let mounted = true;
    if (editor) {
      const selectionChangeHandler = (range: {
        index: number;
        length: number;
      }) => {
        setTimeout(() => {
          if (mounted) {
            handleDisabledHandler(editor, range);
          }
        });
      };

      editor.on('selection-change', selectionChangeHandler);
      return () => {
        mounted = false;
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [handleDisabledHandler, quillInstance]);

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    const range = editor?.getSelection();
    if (editor && range) {
      handleDisabledHandler(editor, range);
    }
  }, [handleDisabledHandler, quillInstance]);

  return (
    <TagContainer
      tag={tag}
      onCheckBoxChange={handleCheckboxChange}
      checkboxSelected={selected}
      onAttrValueChange={handleAttrValueChange}
      attributeValues={attributeValues}
      className={classNames(disabled && Styles.disabled)}
    />
  );
};
