import Quill, { StringMap } from 'quill';
import QuillUtil from 'utils/QuillUtil';
import { AnalyticsEvent, useTrackEvent } from 'services/amplitude';
import { AttributeTypes, TagType, TagModel } from 'modules/common/models/Tags';
import { tagBlotModelFactory } from 'modules/edit/Editor/Blots/tagBlotModelFactory';
import { ALIAS_DEFAULT_VALUE } from 'modules/common/constants';
import useQuill from 'modules/common/hooks/useQuill';

type Props = {
  checked?: boolean;
  setAttributeValues?(
    value: React.SetStateAction<Map<string, string | undefined>>
  ): void;
  setSelected?(value: boolean): void;
};

export const useHandleCheckboxChange = ({
  setAttributeValues,
  setSelected,
}: Props) => {
  const trackEvent = useTrackEvent();
  const quillInstance = useQuill();

  return (tag: TagModel, checked: boolean) => {
    const quill = quillInstance!.getEditor();
    const selection = quill.getSelection(true);
    if (selection) {
      // Cursor tags
      if (tag.tagType === TagType.SentenceTags) {
        if (selection.length) {
          return;
        }

        if (checked) {
          const attrs = tag.attributes.map((a) => {
            if (a.type === AttributeTypes.Enum) {
              return [a.name, `${a.prefix}${a.values![0].value}${a.postfix}`];
            }
            if (a.type === AttributeTypes.Integer) {
              return [a.name, `${a.prefix}${a.defaultValue}${a.postfix}`];
            }

            return [a.name, ''];
          });
          const tagForApply = tagBlotModelFactory(
            tag,
            attrs as [string, string][]
          );
          quill.insertText(
            selection.index,
            '\u00A0',
            tag.id,
            JSON.stringify(tagForApply),
            'user'
          );
          trackEvent({
            eventName: AnalyticsEvent.AddTag,
            customProperties: {
              tagType: { type: tag.tagType, displayName: tag.displayName },
            },
          });
          const newMap = new Map<string, string>(attrs as [string, string][]);
          if (setAttributeValues) setAttributeValues(newMap);
        } else {
          // default before the tag
          let index = selection.index;
          if (tag.id in quill.getFormat(selection)) {
            // after tag
            index = selection.index - 1;
          }
          quill.deleteText(index, 1, 'user');
          trackEvent({
            eventName: AnalyticsEvent.DeleteTag,
            customProperties: {
              tagType: {
                type: tag.tagType,
                displayName: tag.displayName,
              },
            },
          });
        }
        if (setSelected) setSelected(checked);
        return;
      }

      // Selection tags
      if (selection.length) {
        if (setSelected) setSelected(checked);
        if (checked) {
          const attrs = tag.attributes.map((a) => {
            if (a.type === AttributeTypes.Enum) {
              return [a.name, a.values![0].value];
            }

            if (a.type === AttributeTypes.Integer && a.defaultValue) {
              return [a.name, a.defaultValue];
            }

            if (a.defaultValue === ALIAS_DEFAULT_VALUE) {
              return [a.name, quill.getText(selection.index, selection.length)];
            }
            return [a.name, ''];
          });

          const tagForApply = tagBlotModelFactory(
            tag,
            attrs as [string, string][]
          );
          quill.formatText(
            selection,
            tagForApply.id,
            JSON.stringify(tagForApply),
            'user'
          );
          const newMap = new Map<string, string>(attrs as [string, string][]);
          if (setAttributeValues) setAttributeValues(newMap);
        } else {
          quill.formatText(selection, tag.id, false, 'user');
          trackEvent({
            eventName: AnalyticsEvent.DeleteTag,
            customProperties: {
              tagType: {
                type: tag.tagType,
                displayName: tag.displayName,
              },
            },
          });
          if (setAttributeValues) setAttributeValues(new Map());
        }

        quill.blur();
        quill.focus();
      }
    }
  };
};

const isSentenceTagExists = (format: StringMap) =>
  format.hasOwnProperty('sentence');

export const areMultipleSentencesSelected = (
  editor: Quill,
  range: { index: number; length: number }
) => {
  const format = editor.getFormat(range.index, range.length);
  if (!isSentenceTagExists(format)) return false;

  // Get ids within a selected/highlighted area.
  const ids: Record<any, any>[] | string | undefined = format['sentence'];

  return ids && Array.isArray(ids) && ids.length > 1;
};

export const isSelectedSentenceTagAreaFitsTheSkipTagUsage = (
  editor: Quill,
  range: { index: number; length: number }
) => {
  const format = editor.getFormat(range.index, range.length);
  const selectedText = editor.getText(range.index, range.length);

  // If no sentence tag exists.
  if (!isSentenceTagExists(format)) return true;

  // If the only sentence tag (i.e., only one sentence) was selected.
  if (!areMultipleSentencesSelected(editor, range)) return true;

  const sentencesBlot = QuillUtil.getInlineChildren(editor, range) as any[];
  let sentencesFullText = '';
  sentencesBlot.forEach((item) => {
    sentencesFullText = sentencesFullText + item.domNode.textContent;
  });

  // If the selected text and text of whole sentences are equal, let the Skip tag usage be appropriate.
  return (
    !!selectedText.length &&
    !!sentencesFullText.length &&
    selectedText === sentencesFullText
  );
};
