/* eslint-disable no-useless-escape */
import { object, ref, string } from 'yup';

export function getLoginFormSchema() {
  return object().shape({
    email: string().email('Invalid email').required('Email is required'),
    password: string().required('Password is required'),
  });
}

export function getSignupFormSchema() {
  return object().shape({
    name: string().required('Name is required'),
    email: string().email('Invalid email').required('Email is required'),
    password: string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_@#\$%\^&\*])(?=.{8,})/,
        'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
      ),
    confirmPassword: string()
      .required('Confirm password is required')
      .oneOf([ref('password'), null], 'Passwords must match'),
  });
}

export function getActivateAccountFormSchema() {
  return object().shape({
    code: string().required('Code is required'),
  });
}

export function getForgotPasswordFormSchema() {
  return object().shape({
    email: string().email('Invalid email').required('Email is required'),
  });
}

export function getResetPasswordFormSchema() {
  return object().shape({
    code: string().required('Code is required'),
    password: string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
      ),
    confirmPassword: string()
      .required('Confirm password is required')
      .oneOf([ref('password'), null], 'Passwords must match'),
  });
}
