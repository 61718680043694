import { FC, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { getUserGroups, signIn } from 'aws';
import { Button } from 'modules/common/components/Button/Button';
import { Input } from 'modules/common/components/Input/Input';
import { Stack } from 'modules/common/components/Stack/Stack';
import { getLoginFormSchema } from './validation-schemas';
import { yupResolver } from '@hookform/resolvers/yup';

import { Alert } from 'modules/common/components/Alert/Alert';
import Form from 'modules/common/components/Form/Form';
import useLoading from 'modules/common/hooks/useLoading';
import UserContext from 'modules/common/context/UserContext';
import { ADMIN_USER_GROUP, EDITOR_USER_GROUP } from 'modules/common/constants';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';

import Styles from './auth.module.scss';

interface LoginForm {
  email: string;
  password: string;
}

export const Login: FC = () => {
  const navigate = useNavigate();
  const { setUserGroups, setUserSession } = useContext(UserContext);
  const trackEvent = useTrackEvent();
  const {
    isLoading,
    setLoading,
    setError,
    errorMessage: error,
    isError,
  } = useLoading();
  const methods = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(getLoginFormSchema()),
  });

  const {
    formState: { errors },
    setValue,
  } = methods;

  const onSubmit = async (values: LoginForm) => {
    try {
      setLoading(true);
      const cognitoSession = await signIn(values.email, values.password);
      const userGroups = await getUserGroups(cognitoSession);
      setUserGroups(userGroups);
      setUserSession(cognitoSession);
      setLoading(false);
      if (
        userGroups?.includes(ADMIN_USER_GROUP) ||
        userGroups?.includes(EDITOR_USER_GROUP)
      ) {
        trackEvent({
          eventName: AnalyticsEvent.LogIn,
        });
        return navigate('/dashboard');
      }
      navigate('/');
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className={Styles['auth-form']}>
      <h4 className="fw-700 font-size-lg ta-center m-b-5">Login</h4>
      {isError && (
        <Alert className="m-b-2" type="error">
          {error}
        </Alert>
      )}
      <Form<LoginForm> onSubmit={onSubmit} methods={methods}>
        <p className="m-b-1 font-size-sm fw-600">Email</p>
        <Input
          large
          type="email"
          name="email"
          placeholder="Enter e-mail"
          autoComplete="email"
          onInput={(value) => setValue('email', value)}
        />
        <p className="m-b-4 font-size-xs color-error">
          {errors.email?.message}
        </p>

        <Stack className="m-b-1" justifyContent="space-between">
          <Stack.Item>
            <p className="font-size-sm fw-600">Password</p>
          </Stack.Item>
          <Stack.Item>
            <Link
              className="font-size-sm color-primary-i fw-600"
              to="/auth/forgot-password"
            >
              Forgot password?
            </Link>
          </Stack.Item>
        </Stack>
        <Input
          large
          type="password"
          name="password"
          placeholder="Enter password"
          autoComplete="current-password"
          onInput={(value) => setValue('password', value)}
        />
        <p className="m-b-4 font-size-xs color-error">
          {errors.password?.message}
        </p>

        <Stack justifyContent="space-between" alignItems="center">
          <Stack.Item>
            <Link
              className="font-size-sm color-primary-i fw-600"
              to="/auth/sign-up"
            >
              Create an account
            </Link>
          </Stack.Item>
          <Stack.Item>
            <Button type="submit" loading={isLoading} disabled={isLoading}>
              Continue
            </Button>
          </Stack.Item>
        </Stack>
      </Form>
    </div>
  );
};
