import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQuery from './index';
import {
  Dict,
  EditorStatus,
  LabelValue,
  PaginatedRequest,
  PaginatedResponse,
  ParsedVoiceValue,
  ProjectStatus,
  RequestProjectStatus,
  Voice,
  VoiceLabels,
} from 'modules/common/types';
import {
  AdminProjects,
  AdminProjectsPageTable,
  EditorProjectsPageTable,
  EditorRequestedProjects,
} from 'modules/dashboard/ProjectsTableConfig';
import { Editor } from 'modules/dashboard/EditorsTableConfig';
import { ProjectModel } from 'modules/common/models/Project';
import { Notification } from 'modules/common/models/Notifications';
import { PreProcessChapter } from 'modules/common/models/Chapter';
import { ElevenLabsVoiceSettings } from 'modules/projects/Project/types';
import { VoiceSettingsPayload } from 'modules/edit/EditVoiceSettings/types';
import { useAppDispatch } from 'modules/common/hooks/redux';
import {
  GetEnabledFeaturesRequest,
  GetEnabledFeaturesResponse,
  GetIsFeatureEnabledRequest,
  GetIsFeatureEnabledResponse,
  TextToTtsRequest,
  TextToTtsResponse,
  TextToTtsV2Request,
  TextToTtsV2Response,
  TryVoiceRequest,
  TryVoiceResponse,
} from './types';
import { CACHED_AUDIO_ENDPOINT_NAME } from './lib';

type JobStatus = 'IN PROGRESS' | 'STOPPED' | 'FAILED';

interface PreprocessedChapter {
  jobStatus: JobStatus;
  getURL: string;
}

interface VoiceQuery {
  voices: Dict<ParsedVoiceValue[]>;
  models: Record<'elevenlabs', LabelValue[]>;
  enginesWithPreprocessing: Dict<boolean>;
  voicesWithPreprocessing?: Voice[];
  defaultVoiceSettings?: Record<'elevenlabs', ElevenLabsVoiceSettings>;
}

export const apiSlice = createApi({
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: 30, // 30 seconds
  tagTypes: [
    'Editors',
    'UnassignedProjects',
    'RequestedProjects',
    'AllProjects',
    'Notifications',
  ],
  endpoints: (builder) => ({
    // GET Requests
    getVoices: builder.query<VoiceQuery, void>({
      query: () => ({
        url: 'voices',
      }),
      transformResponse: (
        response: Dict<{
          voices: Dict<Voice & { labels?: VoiceLabels }>;
          defaultVoiceSettings?: ElevenLabsVoiceSettings;
          models?: Dict<string>;
        }>
      ) => {
        const data: any = {
          voices: {},
          models: {},
          voicesWithPreprocessing: undefined,
          defaultVoiceSettings: {},
          enginesWithPreprocessing: {},
        };

        for (const engineKey in response) {
          const { voices, defaultVoiceSettings, models } = response[engineKey];
          const voicesNames = Object.keys(voices);
          const voicesMeta = Object.values(voices);
          if (models) {
            const modelKeys = Object.keys(models);
            data['models'][engineKey] = modelKeys.map((key) => ({
              value: key,
              label: models[key],
            }));
          }
          data['voices'][engineKey] = voicesNames.map((item: string) => ({
            value: item,
            label: item,
            pre_process: response[engineKey]?.voices[item]?.pre_process,
            voiceLabels: response[engineKey]?.voices[item]?.labels,
            high_quality_base_model_ids:
              response[engineKey]?.voices[item]?.high_quality_base_model_ids,
          }));
          if (voicesMeta.find((voice) => voice.pre_process)) {
            data['enginesWithPreprocessing'][engineKey] = true;
            data['voicesWithPreprocessing'] = voicesMeta.filter(
              (voice) => voice.pre_process
            );
          }
          if (defaultVoiceSettings) {
            data['defaultVoiceSettings'][engineKey] = defaultVoiceSettings;
          }
        }
        return data;
      },
    }),
    [CACHED_AUDIO_ENDPOINT_NAME]: builder.query<string, any>({
      query: (url: string) => ({
        url,
        responseHandler: (response) =>
          response.blob().then((blob) => URL.createObjectURL(blob)),
      }),
    }),

    getSuperAdminProjects: builder.query<
      PaginatedResponse<AdminProjectsPageTable>,
      PaginatedRequest<AdminProjectsPageTable>
    >({
      query: ({ index, sortBy, sortType, ...filters }) => ({
        url: 'project/status',
        params: {
          index,
          sortBy: sortBy ? sortBy : undefined,
          sortType,
          ...filters,
        },
      }),
      providesTags: ['UnassignedProjects'],
    }),

    getEditorsProjects: builder.query<
      PaginatedResponse<EditorProjectsPageTable>,
      PaginatedRequest<EditorProjectsPageTable>
    >({
      query: ({ index, sortBy, sortType, ...filters }) => ({
        url: 'project/status',
        params: {
          index,
          sortBy: sortBy ? sortBy : undefined,
          sortType,
          ...filters,
        },
      }),
      providesTags: ['RequestedProjects'],
    }),

    getUnassignedProjects: builder.query<
      PaginatedResponse<AdminProjects>,
      void
    >({
      query: () => ({
        url: 'project/status/new',
      }),
      providesTags: ['UnassignedProjects'],
    }),

    getRequestedProjects: builder.query<
      PaginatedResponse<EditorRequestedProjects>,
      void
    >({
      query: () => ({
        url: '/project/status',
        params: {
          projectStatus: ProjectStatus.pending,
        },
      }),
      providesTags: ['RequestedProjects'],
    }),

    getEditors: builder.query<
      PaginatedResponse<Editor>,
      PaginatedRequest<Editor>
    >({
      query: ({ sortBy, sortType, ...filters }) => ({
        url: 'editor',
        params: {
          sortBy: sortBy ? sortBy : undefined,
          sortType,
          ...filters,
        },
      }),
      providesTags: ['Editors'],
    }),

    getPreprocessedChapter: builder.query<
      PreprocessedChapter,
      {
        projectId: string;
        chapterId: string | undefined;
      }
    >({
      query: ({ projectId, chapterId }) => ({
        url: 'pre_process',
        params: {
          projectId,
          chapterId,
        },
      }),
    }),

    getProjects: builder.query<ProjectModel[], void>({
      query: () => ({
        url: '/project',
      }),
      transformResponse: (projects: ProjectModel[]): ProjectModel[] => {
        // TODO this will be delete so nothing to worry about
        const data: ProjectModel[] = [];

        projects.forEach((item: any) => {
          // sanitize weird books
          if ('chapters' in item && Object.keys(item.chapters).length) {
            data.push({
              id: item.id,
              name: item.name,
              fileName: item.fileName,
              ttsEngine: item.ttsEngine,
              voice: item.voice,
              elevenLabsModel: item.elevenLabsModel,
              reviewers: item.reviewers,
              fileType: item.fileType,
              projectStatus: item.projectStatus,
              author: item.author,
              chapters: item.chapters,
              orders: item.orders,
            });
          }
        });
        return data;
      },
      providesTags: ['AllProjects'],
    }),

    getCurrentUserStatus: builder.query<
      { editorCapacity: number; editorStatus: EditorStatus },
      void
    >({
      query: () => ({
        url: '/editor/availability',
      }),
    }),

    getNotification: builder.query<
      PaginatedResponse<Notification<any>>,
      { index: number; projectId?: string }
    >({
      query: ({ index, projectId }) => ({
        url: '/project/notifications',
        params: {
          index,
          projectId,
        },
      }),
    }),

    getEnabledFeatures: builder.query<
      GetEnabledFeaturesResponse,
      GetEnabledFeaturesRequest
    >({
      query: ({ context }) => ({
        url: 'v2/feature_flags',
        params: context,
      }),
    }),

    getIsFeatureEnabled: builder.query<
      GetIsFeatureEnabledResponse,
      GetIsFeatureEnabledRequest
    >({
      query: ({ featureName, context }) => ({
        url: 'v2/feature_flags/resolve',
        params: {
          name: featureName,
          ...context,
        },
      }),
    }),

    // POST Requests
    assignEditor: builder.mutation<
      string,
      {
        projectId: string;
        editorId: string;
      }
    >({
      query: (body) => ({
        url: 'editor/assign',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Editors', 'UnassignedProjects'],
    }),

    acceptBook: builder.mutation<
      string,
      | {
          projectId: string;
          editorId: string;
          decision: RequestProjectStatus.Accept;
        }
      | {
          projectId: string;
          editorId: string;
          decision: RequestProjectStatus.Decline;
          declinedReason: string;
        }
    >({
      query: (body) => ({
        url: 'editor/decision',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['RequestedProjects'],
    }),

    changeProjectStatus: builder.mutation<
      void,
      { id: string; projectStatus: ProjectStatus }
    >({
      query: (body) => ({
        url: 'project/status',
        method: 'POST',
        body: body,
      }),
    }),

    changeEditorAvailability: builder.mutation<
      { editorCapacity: number; editorStatus: EditorStatus },
      EditorStatus
    >({
      query: (body) => ({
        url: '/editor/availability',
        method: 'POST',
        body: {
          editorStatus: body,
        },
      }),
    }),

    preprocessChapter: builder.mutation<string, PreProcessChapter>({
      query: (body) => ({
        url: 'pre_process',
        method: 'POST',
        body,
      }),
    }),

    tryVoice: builder.query<TryVoiceResponse, TryVoiceRequest>({
      query: (body) => ({
        url: 'v2/projects/try_voice',
        method: 'POST',
        body,
        responseHandler: 'json',
      }),
    }),

    textToTts: builder.query<TextToTtsResponse, TextToTtsRequest>({
      query: (body) => ({
        url: '/text_to_tts',
        method: 'POST',
        body,
        responseHandler: 'json',
      }),
    }),

    textToTtsV2: builder.query<TextToTtsV2Response, TextToTtsV2Request>({
      query: (body) => ({
        url: 'v2/text-to-tts',
        method: 'POST',
        body,
        responseHandler: 'json',
      }),
    }),

    changeNotificationStatus: builder.mutation<
      void,
      { id: string; isRead: boolean }
    >({
      query: (body) => ({
        url: 'project/notifications',
        method: 'PUT',
        body: body,
      }),
    }),

    changeProjectVoiceSettings: builder.mutation<
      ProjectModel,
      { projectId: string; body: VoiceSettingsPayload }
    >({
      query: ({ projectId, body }) => ({
        url: `v2/projects/${projectId}/voice_settings`,
        method: 'PUT',
        body: body,
      }),
    }),

    // Delete a project
    deleteProject: builder.mutation<string, string>({
      query: (id) => ({
        url: 'project',
        method: 'DELETE',
        body: {
          id,
        },
      }),
      invalidatesTags: ['AllProjects', 'UnassignedProjects'],
    }),
  }),
});

export const useInvalidateProjectsList = () => {
  const dispatch = useAppDispatch();
  return () =>
    dispatch(
      apiSlice.util.invalidateTags(['AllProjects', 'UnassignedProjects'])
    );
};

export const {
  useGetVoicesQuery,
  useGetSuperAdminProjectsQuery,
  useGetEditorsProjectsQuery,
  useGetUnassignedProjectsQuery,
  useGetRequestedProjectsQuery,
  useGetEditorsQuery,
  useGetCurrentUserStatusQuery,
  useGetNotificationQuery,
  useGetProjectsQuery,
  useGetPreprocessedChapterQuery,
  useGetEnabledFeaturesQuery,
  useGetIsFeatureEnabledQuery,
  useLazyGetIsFeatureEnabledQuery,
  useLazyTryVoiceQuery,
  useLazyTextToTtsQuery,
  useLazyTextToTtsV2Query,
  useLazyGetCachedAudioFromUrlQuery,
  useAssignEditorMutation,
  useAcceptBookMutation,
  useChangeNotificationStatusMutation,
  useChangeProjectVoiceSettingsMutation,
  usePreprocessChapterMutation,
  useDeleteProjectMutation,
  useChangeProjectStatusMutation,
  useChangeEditorAvailabilityMutation,
} = apiSlice;
