import { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { AttributeModel, AttributeTypes } from 'modules/common/models/Tags';
import { Select } from 'modules/common/components/Select/Select';
import { Input } from 'modules/common/components/Input/Input';
import { SelectValue } from 'modules/common/types';
import { Stack } from 'modules/common/components/Stack/Stack';
import Slider from 'modules/common/components/Slider/Slider';
import { setReactInputValue } from 'utils/NativeValueSetter';
import useQuill from 'modules/common/hooks/useQuill';
import { Normalizers } from 'modules/common/Normalizers/SpeedVolumeNormalizer';
import Styles from './insert-text.module.scss';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'modules/common/hooks/redux';
import { shouldFocusAliasInputSelector } from 'redux/reducers/focus/selectors';
import { setFocusAliasInput } from 'redux/reducers/focus/focusSlice';

interface AttributeProps {
  attribute: AttributeModel;
  value: string | undefined;
  onChange: (attr: AttributeModel, value?: string) => void;
}

const TextAttribute: FC<AttributeProps> = ({ attribute, value, onChange }) => {
  const dispatch = useDispatch();
  const shouldFocusAliasInput = useAppSelector(shouldFocusAliasInputSelector);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChangeInput = (v: string) => onChange(attribute, v);

  const isAliasInput =
    attribute.name === 'alias' && attribute.type === 'string';

  useEffect(() => {
    if (shouldFocusAliasInput && inputRef.current && isAliasInput) {
      inputRef.current.focus();
      dispatch(setFocusAliasInput(false));
    }
  }, [shouldFocusAliasInput, isAliasInput, dispatch]);

  return (
    <Input
      id="text-attribute-input"
      placeholder={attribute.displayName}
      small
      name={attribute.name}
      value={value}
      onChangeWithValueHandler={handleChangeInput}
      ref={inputRef}
    />
  );
};

const SelectAttribute: FC<AttributeProps> = ({
  attribute,
  value,
  onChange,
}) => {
  const quillInstance = useQuill();

  const selectOptions = useMemo(() => {
    return attribute.values!.map((a) => ({
      value: a.value,
      label: a.name,
    }));
  }, [attribute]);

  const handleSelectChange = useCallback(
    (value: SelectValue) => {
      onChange(attribute, value?.value);
    },
    [attribute, onChange]
  );

  const valueMemo = useMemo(() => {
    let v = selectOptions[0];
    if (value) {
      const val = attribute.values!.find(
        (v) =>
          v.value ===
          value
            .split('')
            .filter(
              (item) => item !== attribute.postfix && item !== attribute.prefix
            )
            .join('')
      )!;
      v = {
        label: val?.name || '',
        value: value,
      };
    }
    return v;
  }, [
    attribute.values,
    selectOptions,
    value,
    attribute.postfix,
    attribute.prefix,
  ]);

  const showSelect = selectOptions.length > 1;

  return showSelect ? (
    <Select
      value={valueMemo}
      onChange={handleSelectChange}
      options={selectOptions}
      size="small"
      onMenuOpen={() => {
        quillInstance?.focus();
      }}
    />
  ) : null;
};

const NumericAttribute: FC<AttributeProps> = ({
  attribute,
  value,
  onChange,
}) => {
  const { converters } = attribute;
  const sliderRef = useRef<HTMLInputElement>(null);
  const parsedInputValue = useMemo(() => {
    if (converters) {
      const ssmlToUiConverter = Normalizers[
        converters.ssmlToUi as keyof Normalizers
      ] as any;

      return ssmlToUiConverter(
        parseFloat(value ? value : attribute.defaultValue!)
      );
    }
    return parseFloat(value ? value : (attribute.defaultValue as string));
  }, [attribute.defaultValue, converters, value]);

  const onElementChange = (value: number) => {
    if (converters) {
      const uiToSsmlConverter = Normalizers[
        converters.uiToSSML as keyof Normalizers
      ] as any;
      onChange(attribute, uiToSsmlConverter(value));
    } else {
      onChange(attribute, `${value}${attribute.postfix}`);
    }
  };

  const getValueLimitedToMax = (value: string) => {
    let val = parseFloat(value);
    if (attribute.max) {
      return Math.min(val, attribute.max);
    }
    return Math.min(val, 200);
  };

  const onInputChange = (value: string) => {
    const val = getValueLimitedToMax(value);
    if (sliderRef.current) {
      setReactInputValue(sliderRef.current, value);
    }
    onElementChange(val);
  };

  const onSliderChange = (value: string) => onElementChange(parseFloat(value));

  return (
    <Stack spacing="2" justifyContent="flex-end">
      <Stack.Item>
        <Slider
          min={attribute.min ? attribute.min : 0}
          max={attribute.max ? attribute.max : 200}
          defaultValue={parsedInputValue}
          onChange={(evt) => onSliderChange(evt.target.value)}
          ref={sliderRef}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack alignItems="center">
          <Stack.Item>
            <Input
              className={Styles['slider-input']}
              min={attribute.min}
              max={attribute.max}
              value={parsedInputValue}
              type="number"
              onChangeWithValueHandler={(val) => onInputChange(val)}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

const attributeComponentMap = new Map([
  [AttributeTypes.Enum, SelectAttribute],
  [AttributeTypes.String, TextAttribute],
  [AttributeTypes.Integer, NumericAttribute],
]);

export const Attribute: FC<AttributeProps> = ({
  attribute,
  value,
  onChange,
}) => {
  const Component = attributeComponentMap.get(attribute.type);
  if (!Component) return null;

  return <Component value={value} attribute={attribute} onChange={onChange} />;
};
