import React, { FC, SyntheticEvent, useRef } from 'react';
import { Modal } from 'modules/common/components/Modal/Modal';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import CheckboxList from 'modules/common/components/CheckboxList/CheckboxList';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import { useAppSelector } from 'modules/common/hooks/redux';
import { selectAll } from 'redux/reducers/chapters/selectors';

import Styles from './exportpopup.module.scss';
import { Divider } from 'modules/common/components/Divider/Divider';
import ExportApiInstance from 'modules/edit/api/Export';
import {
  activeProjectSelectorId,
  isProjectStatusDone,
  projectEngineSelector,
} from 'redux/reducers/project/selectors';
import { downloadAudioByIframe, listStatusChecker } from 'utils';
import { error } from 'modules/common/components/Notify';
import { ConfirmPopup } from 'modules/common/components/Confirm';
import useCheckboxes from 'modules/common/hooks/useCheckboxes';

interface Props {
  setClose: (e?: SyntheticEvent<HTMLElement>) => void;
  isOpen: boolean;
  exportLoading: boolean;
  setExportLoading: (value: boolean) => void;
  saveAllChapters: () => void;
  isAnyChapterDirty: boolean;
}

const ExportPopup: FC<Props> = ({
  setClose,
  isOpen,
  setExportLoading,
  saveAllChapters,
  isAnyChapterDirty,
  exportLoading,
}) => {
  const chapters = useAppSelector(selectAll);
  const {
    checkedCheckboxes,
    setCheckboxes,
    setAllCheckboxes,
    unSetCheckBoxes,
    unSetAllCheckboxes,
    allCheckbox,
  } = useCheckboxes<string>([], chapters.length);
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);
  const projectId = useAppSelector(activeProjectSelectorId);
  const projectEngine = useAppSelector(projectEngineSelector);
  const isProjectDone = useAppSelector(isProjectStatusDone);

  const handleExport = async () => {
    if (!projectId) return;
    setExportLoading(true);

    if (isAnyChapterDirty && !isProjectDone) {
      await saveAllChapters();
    }
    onModalClose();

    let checkboxes = Array.from(checkedCheckboxes.values());
    const currentProjectId = projectId;

    await ExportApiInstance.startTheProcess(
      projectId,
      projectEngine,
      checkboxes
    );

    intervalId.current = setInterval(async () => {
      try {
        const result = await ExportApiInstance.getTheProcessStatus(
          projectId,
          projectEngine
        );
        const audioIds = result.map((curr) => curr.audio_id);

        const { isRunning, hasError, isFinished } = listStatusChecker(result);
        if (isRunning) {
          return;
        }

        if (hasError || isFinished) {
          setExportLoading(false);
          if (hasError) {
            error({ children: 'Something went wrong during the export' });
          }

          if (intervalId.current) {
            clearInterval(intervalId.current);
          }
        }

        if (isFinished) {
          await onDownloadConfirmation(currentProjectId, audioIds);
        }
      } catch (e) {
        setExportLoading(false);
        if (intervalId.current) {
          clearInterval(intervalId.current);
          return;
        }
      }
    }, 5000);
  };

  const downloadTheAudios = async (
    projectId: string,
    checkboxesIds: string[]
  ) => {
    if (!projectId) return;
    const preAssignedUrls = await ExportApiInstance.getPreAssignedAudio(
      projectId,
      checkboxesIds
    );

    for (const pKey in preAssignedUrls) {
      downloadAudioByIframe(preAssignedUrls[pKey].getUrl);
    }
  };

  const handleSingleCheckboxChange = (id: string, value: boolean) => {
    if (value) {
      setCheckboxes(id);
      return;
    }

    unSetCheckBoxes(id);
  };

  const handleCheckAll = (value: boolean) => {
    if (value) {
      const ids = chapters.map((item) => item.id);
      setAllCheckboxes(ids);
      return;
    }

    unSetAllCheckboxes();
  };

  const onModalClose = () => {
    setClose();
    unSetAllCheckboxes();
  };

  const onDownloadConfirmation = (
    currentProjectId: string,
    audioIds: string[]
  ) => {
    ConfirmPopup({
      header: 'Download Audio',
      buttonChild: 'Download',
      bodyChild: (
        <>
          Your Selected Audio are exported and ready to download
          <Stack justifyContent="center">Do you want to download them?</Stack>
        </>
      ),
      onAccept: async () => {
        await downloadTheAudios(currentProjectId, audioIds);
      },
    });
  };

  return (
    <Modal
      onClose={onModalClose}
      portal
      open={isOpen}
      isCloseIcon
      className={Styles['popup']}
      gray
    >
      <Stack justifyContent="center" className="m-b-4">
        Export audiobook
      </Stack>
      <Stack direction="column">
        <Stack.Item className={Styles['checkboxAll']}>
          <Stack spacing="2" alignItems="center" justifyContent="space-between">
            <Stack.Item className="m-r-1">All Chapters</Stack.Item>
            <Stack.Item>
              <Checkbox checked={!!allCheckbox} onChange={handleCheckAll} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Divider />
        <Stack.Item className={Styles['checkboxContainer']}>
          {chapters?.length && (
            <CheckboxList
              listOfItems={chapters}
              onChangeCheckbox={handleSingleCheckboxChange}
              checkedIds={checkedCheckboxes}
            />
          )}
        </Stack.Item>
      </Stack>
      <Stack className="m-t-4" alignItems="center" justifyContent="center">
        <Stack.Item>
          <Button
            onClick={handleExport}
            loading={exportLoading}
            primary
            disabled={exportLoading}
          >
            Export audiobook
          </Button>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default ExportPopup;
