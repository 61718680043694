import { FC, MouseEvent } from 'react';
import { Icon } from 'icons';
import { ActionMenu } from 'modules/common/components/ActionMenu/ActionMenu';
import { useActions } from 'modules/common/hooks/useActions';
import { useDeleteProjectMutation } from 'redux/api/app.api';
import { useNavigate } from 'react-router-dom';

interface Props {
  id: string;
}

const ActionsCell: FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const { actionMenuOpen, handleCloseActions, handleOpenActions } =
    useActions();

  const [deleteProject, { isLoading }] = useDeleteProjectMutation();

  const handleDelete = async (e: MouseEvent) => {
    e.stopPropagation();

    if (isLoading) return;
    handleCloseActions();
    await deleteProject(id);
  };

  const handleEditMode = (e: MouseEvent) => {
    e.stopPropagation();

    if (isLoading) return;
    navigate(`/projects/edit/${id}`);
  };

  return (
    <ActionMenu
      direction="bl"
      portal
      open={actionMenuOpen}
      width="xs"
      trigger={<Icon onClick={handleOpenActions} name="more_horiz" size={20} />}
      onClickOutside={handleCloseActions}
    >
      <ActionMenu.Item onClick={handleEditMode}>Edit</ActionMenu.Item>
      <ActionMenu.Item onClick={handleDelete}>Delete</ActionMenu.Item>
    </ActionMenu>
  );
};

export default ActionsCell;
